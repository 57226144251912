/* eslint-disable */
const { latinizeIt } = require('./latinizeIt');

function trim(string) {
  if (string.trim) {
    return string.trim();
  }
  return string.replace(/^\s+|\s+$/g, '');
}

exports.makeSlug = ({ inputVar, allowSlash }) => {
  // eslint-disable-line no-undef
  /* no longer using jquery trim since it wont work server-side */
  let result;
  if (inputVar) {
    //  not using this method now since jquery doesnt work server-side
    //  var slug = jQuery.trim(theslug) // Trimming recommended by Brooke Dukes - http://www.thewebsitetailor.com/2008/04/jquery-slug-plugin/comment-page-1/#comment-23
    const replaceVar = (allowSlash && /[^a-zA-Z0-9/\-]/g) || /[^a-zA-Z0-9\-]/g  // allow "/" so that we can have this function work for url paths - needed to include "category" paths for Shop and Teams
    result = trim(latinizeIt({ inputVar, allowSlash }))
        .replace(/&/g, 'and')
        .replace(/\s+/g, '-')
        .replace(/[[:alpha:]]+/g, '-')
        .replace(/,/g, '-')
        .replace(replaceVar, '')
        .replace(/\-{2,}/g, '-')
        .replace(/(^-)|(-$)/g, ''); // remove leading and trailing hyphens.
  }
  result = encodeURI(result); // finally, run it through encodeURI to ensure we dont have any unacceptable characters...
  return result;
};
